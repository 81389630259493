import React from "react"
import _ from "lodash"

import {slideDown, slideUp} from "./../utils"

export default class Fag extends React.Component {
    constructor(props) {
        super(props);

        this.timeTransition = 500;

        this.state = {
            actual: 'closed',
            animating: false
        };
    }
    toggleFaq(){
        if(!this.state.animating){
            this.setState({animating:true});
            if(_.includes(this.state.actual, 'closed')){
                this.setState({actual:'opened'});
            }
            if(_.includes(this.state.actual, 'opened')){
                this.setState({actual:'animation closed'});
                setTimeout(() => {this.setState({actual:'closed'})}, this.timeTransition);
            }
            setTimeout(() => {this.setState({animating:false})}, this.timeTransition);
        }
    }
    UNSAFE_componentWillUpdate(nextProps, nextState){
        if(_.includes(nextState.actual, 'opened') && _.includes(this.state.actual, 'closed')){
            slideDown(this.refs.text,this.timeTransition);
        }
        if(_.includes(nextState.actual, 'animation')){
            slideUp(this.refs.text,this.timeTransition);
        }
    }
    render() {
        let {id, question, answer} = this.props.faq;
        return (
            <li className={'faq ' + (this.state.actual) }>
                <div className="faq-title" onClick={ev => {
                    // eslint-disable-next-line
                    var s_objectID=`faqs_question${id}`;
                    this.toggleFaq.bind(this)();
                }}>
                    {question}
                </div>
                <div className="faq-text" ref="text">
                    <p>{answer}</p>
                </div>
            </li>
        );
    }
}
