import React from 'react'
import { graphql } from 'gatsby';
import { Translation } from 'react-i18next';
import { withI18next, Link } from '@wapps/gatsby-plugin-i18next';
import Layout from 'layouts/index'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {setMarkdown} from 'store/modules/modal';

import {markdown} from 'utils'

import Faq from 'macros/Faq.jsx'

import reactStringReplace from 'react-string-replace'

import analytics from 'utils/analytics'

class IndexPage extends React.Component {
    clickPdf(pdf){
        analytics({
            "downloadPDF": "startBookAgain",
            "pdfName": "requirements",
            "pdfURL": pdf
        })
    }
    render() {
        const {props} = this;
        const {s3Url} = props.data.site.siteMetadata;

        return (<Translation>{t => (
            <Layout name={'indexPage'} {...props}>
                <div className="content-block">
                    {props.data && props.data.allGroupsList && props.data.allGroupsList.edges.map((edge, g)  => {
                        let group = edge.node;
                        return <div className="home-block" key={g}>
                            <div className="home-block-portrait">
                                <img src={s3Url + group.banner} alt={group.title}/>
                            </div>
                            <div className="home-block-content container-fluid">
                                <div className="row">
                                    <div className="col-md-8 col-md-offset-2">
                                        <h4>{t('reservationsAndActivities')}</h4>
                                        {group.buildings.map((building, b) =>
                                            <div key={b} className="description">
                                                <h2>{building.subtitle}</h2>
                                                <div className="description-text" dangerouslySetInnerHTML={markdown(building.description)}></div>
                                                <a href={s3Url + building.requirements_pdf} target="_BLANK" rel="noopener noreferrer" className="link with-svg" onClick={ev => {this.clickPdf(s3Url + building.requirements_pdf)}}>
                                                    <svg viewBox="0 0 270.3 309.3"><path style={{fill: "#E2574C"}} d="M19.3 0h164.2l87 86.7v203.2c0 10.7-8.7 19.3-19.3 19.3H19.3C8.6 309.2 0 300.6 0 289.9V19.3C-.1 8.6 8.6 0 19.3 0z"/><path style={{fill: "#B53629"}} d="M270.3 87h-67.4c-10.7 0-19.3-8.7-19.3-19.3V.2L270.3 87z"/><path style={{fill: "#FFFFFF"}} d="M227.5 138.2c4.8 0 7.1-4.1 7.1-8.2 0-4.2-2.4-8.2-7.1-8.2h-27.1c-5.3 0-8.3 4.4-8.3 9.2v66.6c0 5.9 3.4 9.2 8 9.2 4.5 0 7.9-3.3 7.9-9.2v-18.3h16.4c5.1 0 7.6-4.2 7.6-8.3 0-4-2.5-8.1-7.6-8.1H208v-24.8c.1.1 19.5.1 19.5.1zm-91.6-16.4h-19.8c-5.4 0-9.2 3.7-9.2 9.2v66.7c0 6.8 5.5 8.9 9.4 8.9h20.8c24.6 0 40.9-16.2 40.9-41.2 0-26.4-15.3-43.6-42.1-43.6zm1 68.4h-12.1v-51.9h10.9c16.5 0 23.7 11.1 23.7 26.3-.1 14.3-7.1 25.6-22.5 25.6zm-72.7-68.4H44.6c-5.6 0-8.7 3.7-8.7 9.2v66.6c0 5.9 3.6 9.2 8.3 9.2s8.3-3.3 8.3-9.2v-19.4h12.3c15.2 0 27.7-10.8 27.7-28.1.1-16.9-12-28.3-28.3-28.3zm-.3 40.8H52.6v-25.1h11.3c7 0 11.4 5.5 11.4 12.6 0 7-4.4 12.5-11.4 12.5z"/></svg>
                                                    <span>{t('downloadRequeriments')}</span>
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-md-offset-2">
                                        <h2>{t('selectVisitTypeTitle')}</h2>
                                        {/* <Link to={"/request"} className="btn btn-accept">{t('selectVisitTypeButton')}</Link> */}
                                        <a href="#" className="btn btn-accept disabled">{t('selectVisitTypeButton')}</a>
                                        <div className="legal-advise">
                                            <div onClick={()=> {props.setMarkdown('useconditions')}}>{t('legalAdvise')}</div>
                                        </div>
                                        <div className="cancel-reservation">
                                            {reactStringReplace(t('cancelVisit'),'{link}',(match, i) => (
                                                <Link key={i} to={'/cancel'}>{t('cancelVisitLinkText')}</Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="home-block-image">
                                {group.images.length && group.images.map((i,k) => <img key={k} src={s3Url  + i.file} alt={i.alt}/>)}
                            </div>
                            <div className="home-block-how-to-arrive">
                                {group.buildings.map((building, b) => <div className="" key={b}>
                                    <div className="how-to-arrive-image absolute-col-md absolute-col-md-5">
                                        <img src={s3Url + building.gmap_image} alt=""/>
                                    </div>
                                    <div className="how-to-arrive-info col-md-6 col-md-offset-6">
                                        <h4>{t('howToArrive')}</h4>
                                        <h2>{building.subtitle}</h2>
                                        <div className="how-to-arrive-text" dangerouslySetInnerHTML={markdown(building.arrival_description)}/>
                                        <div className="how-to-arrive-links">
                                            <a className="link with-svg" download="document.pdf" target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/SEAT+F%C3%A0brica+-+Acc%C3%A9s+Nord+1/@41.504073,1.893824,15.02z/data=!4m13!1m7!3m6!1s0x0:0x0!2zNDHCsDMwJzE0LjciTiAxwrA1NCcwOS4wIkU!3b1!8m2!3d41.5040729!4d1.9025096!3m4!1s0x12a48957ced6e997:0x95b0ad05205c66ce!8m2!3d41.504073!4d1.9025094">
                                                <svg viewBox="0 0 512 512"><path style={{fill: "#CCCCCC"}} d="M451.8 239.5v217.7l-68.4-12.7-30.2-36.2-127-141.2 107.9-76.6c54 71.3 55.7 53.9 55.7 53.9s26.7-39.9 60.9-5.6l1.1.7z"/><path style={{fill: "#518EF8"}} d="M190.7 223.1L415 511.8c-1.3.1-2.6.2-4 .2H44.3l146.4-288.9z"/><path style={{fill: "#28B446"}} d="M334.1 190.5L.4 471.2V101.4c0-22.5 18.3-40.8 40.8-40.8h243.3c-10.4 18 28.3 29.5 28.3 51.8 0 22.4 10.7 60 21.3 78.1z"/><path style={{fill: "#F2F2F2"}} d="M451.8 457.2v14.1c0 21.2-16.1 38.6-36.8 40.6L227.3 326.6l45.4-46L377.6 384l5.8 5.7 68.4 67.5z"/><path style={{fill: "#FFD837"}} d="M322.5 230.1l-49.8 50.4-45.4 46L44.3 512h-3.1C18.7 512 .4 493.7.4 471.2l284.3-288.1c5.3 9.1 41.9-.1 49.4 7.3l13.7 17.6c6.6 7.1-31 14.5-25.3 22.1z"/><path style={{fill: "#FFFFFF"}} d="M106.9 227.8c-35.8 0-64.9-29.1-64.9-64.9S71.1 98 106.9 98c17.3 0 33.6 6.8 45.9 19l-15 15c-8.2-8.2-19.2-12.8-30.9-12.8-24.1 0-43.6 19.6-43.6 43.6s19.6 43.6 43.6 43.6c20.4 0 37.6-14.1 42.3-33h-42.3v-21.3h64.9v10.6c0 35.9-29.1 65.1-64.9 65.1z"/><path style={{fill: "#F14336"}} d="M284.5 60.6C305.6 24.4 344.8 0 389.8 0 457 0 511.6 54.5 511.6 121.7c0 16.9-3.4 32.9-9.6 47.5-6.2 14.6-15.2 27.8-26.2 38.8-9.2 9.8-17.5 20.2-24.9 30.8-48.7 69.4-60.9 148.7-60.9 148.7s-13.3-86.1-67.3-157.4c-5.7-7.6-11.9-14.9-18.6-22.1-7.4-7.4-13.9-15.7-19.2-24.8-10.6-18-16.6-39-16.6-61.4-.2-22.3 5.7-43.2 16.2-61.2z"/><path style={{fill: "#7E2D25"}} d="M389.8 56.9c35.8 0 64.8 29 64.8 64.8s-29 64.8-64.8 64.8-64.8-29-64.8-64.8 29-64.8 64.8-64.8z"/></svg>
                                                {t('viewOnMap')}
                                            </a>
                                            <a href={s3Url + building.arrival_pdf} className="link with-svg" download="document.pdf" target="_blank" rel="noopener noreferrer" onClick={ev => {this.clickPdf(s3Url + building.arrival_pdf)}}>
                                                <svg viewBox="0 0 270.3 309.3"><path style={{fill: "#E2574C"}} d="M19.3 0h164.2l87 86.7v203.2c0 10.7-8.7 19.3-19.3 19.3H19.3C8.6 309.2 0 300.6 0 289.9V19.3C-.1 8.6 8.6 0 19.3 0z"/><path style={{fill: "#B53629"}} d="M270.3 87h-67.4c-10.7 0-19.3-8.7-19.3-19.3V.2L270.3 87z"/><path style={{fill: "#FFFFFF"}} d="M227.5 138.2c4.8 0 7.1-4.1 7.1-8.2 0-4.2-2.4-8.2-7.1-8.2h-27.1c-5.3 0-8.3 4.4-8.3 9.2v66.6c0 5.9 3.4 9.2 8 9.2 4.5 0 7.9-3.3 7.9-9.2v-18.3h16.4c5.1 0 7.6-4.2 7.6-8.3 0-4-2.5-8.1-7.6-8.1H208v-24.8c.1.1 19.5.1 19.5.1zm-91.6-16.4h-19.8c-5.4 0-9.2 3.7-9.2 9.2v66.7c0 6.8 5.5 8.9 9.4 8.9h20.8c24.6 0 40.9-16.2 40.9-41.2 0-26.4-15.3-43.6-42.1-43.6zm1 68.4h-12.1v-51.9h10.9c16.5 0 23.7 11.1 23.7 26.3-.1 14.3-7.1 25.6-22.5 25.6zm-72.7-68.4H44.6c-5.6 0-8.7 3.7-8.7 9.2v66.6c0 5.9 3.6 9.2 8.3 9.2s8.3-3.3 8.3-9.2v-19.4h12.3c15.2 0 27.7-10.8 27.7-28.1.1-16.9-12-28.3-28.3-28.3zm-.3 40.8H52.6v-25.1h11.3c7 0 11.4 5.5 11.4 12.6 0 7-4.4 12.5-11.4 12.5z"/></svg>
                                                {t('downloadArrive')}
                                            </a>
                                        </div>
                                        <div className="how-to-arrive-text">
                                            <ul>
                                                <li dangerouslySetInnerHTML={markdown(group.additional_information)}/>
                                            </ul>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                            <div className="home-block-faq container-fluid">
                                <div className="row">
                                    <div className="col-md-10 col-md-offset-1">
                                        <h2>{t('questionsAndAnswers')}</h2>
                                        {group.faqs.length > 0 && <ul className="faq-list">
                                            {group.faqs.map((f, i) => <Faq key={i} faq={f}/>)}
                                        </ul>}
                                    </div>
                                </div>
                            </div>
                        </div>;
                    }, this)}
                </div>
            </Layout>
        )}</Translation>);
    }
}

export const query = graphql`
  query($lng: String!) {
      allGroupsList(filter: { _lang: { eq: $lng } },sort:{fields: [_id], order: ASC}) {
        edges {
          node {
            id
            title
            slug
            subtitle
            introduction
            description
            banner
            additional_information
            images {
              alt
              file
            }
            faqs {
              id
              question
              answer
            }
            buildings{
                slug
                title
                subtitle
                description
                requirements_pdf
                gmap_image
                arrival_description
                arrival_pdf
                lat
                long
            }
          }
        }
      }
      locales: allLocale(filter: { lng: { eq:$lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
      }
      site {
        siteMetadata {
          siteTitle
          activeEnv
          analytics
          s3Url
        }
      }
  }
`;

const mapStateToProps = state => ({
    legalNote: state.main.links.legalNote
})

const mapDispatchToProps = dispatch => bindActionCreators({
    setMarkdown
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(withI18next()(IndexPage))
